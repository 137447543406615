(function (window) {
  const showDemoButtons = document.querySelectorAll('.showDemo');
  const demoModal = document.getElementById('demoModal');
  const content = demoModal.querySelector('.content');
  const closeIcon = content.querySelector('.text svg');
  const form = content.querySelector('form');
  const okButton = content.querySelector('.ok');
  const button = form.getElementsByTagName('button')[0];
  const inputs = form.querySelectorAll('input:not([type=hidden]');
  const api = form.action;
  const validationRules = {
    name: /.{2,}/g,
    email: /.*@.*\..*/g,
    phone: /.{2,}/g,
    project: /.{2,}/g,
    comment: /.?/g,
  };

  showDemoButtons.forEach((item) => {
    item.onclick = (e) => {
      e.preventDefault();
      demoModal.style.display = 'flex';
      window?.dataLayer?.push ({ event: 'request_demo_form_view' });
      window.location.hash = '#form-demo';
    }
  });

  content.onmousedown = (e) => {
    e.stopPropagation();
  };

  [okButton, demoModal, closeIcon].forEach((item) => {
    item.onmousedown = () => {
      content.classList.remove('success');
      demoModal.style.display = 'none';
      window.location.hash = '';
      history.replaceState(null, null, ' ');
    };
  })

  Array.prototype.last = function() {
    return this[this.length - 1];
  };

  const isEmailValid = (email, rule) => {
    const notCorporateDomains = [
      'yandex.ru',
      'yandex.kz',
      'yandex.by',
      'yandex.com',
      'ya.ru',
      'narod.ru',
      'mail.ru',
      'mail.com',
      'inbox.ru',
      'list.ru',
      'bk.ru',
      'internet.ru',
      'rambler.ru',
      'rambler.ua',
      'lenta.ru',
      'autorambler.ru',
      'myrambler.ru',
      'ro.ru',
      'gmail.com',
      'gmail.ru',
      'googlemail,com',
      'yahoo.com',
      'aol.com',
      'comcast.net',
      'hotmail.com',
      'outlook.com',
      'icloud.com',
    ];

    return !!email.match(rule) && !notCorporateDomains.includes(email.split('@').last().replaceAll(' ', ''));
  };

  const isInputValid = (input, isEmail) => {
    const rule = validationRules[input.name];

    return isEmail ? isEmailValid(input.value, rule) : input.value.match(rule);
  };

  const validateForm = () => {
    let isFormValid = [];

    inputs.forEach((item) => {
      const isValid = isInputValid(item, item.name === 'email');

      if(isValid) {
        isFormValid.push(true);
      } else {
        isFormValid.push(false);
      }
    });

    return isFormValid;
  };

  const updateInputsClasses = () => {
    let isFormValid = [];

    inputs.forEach((item) => {
      const isEmail = item.name === 'email';
      const isValid = isInputValid(item, isEmail);
      const isEmailFilled = isEmail && item.value.length > 0;
      const inputWrapper = item.parentElement.parentElement;

      if (isValid) {
        inputWrapper.classList.remove('invalid');
        isFormValid.push(true);
      } else {
        button.classList.add('invalid');
        inputWrapper.classList.add('invalid');
        isFormValid.push(false);
      }
      
      if (isEmailFilled) {
        inputWrapper.classList.add('filled');
      } else {
        inputWrapper.classList.remove('filled');
      }
    });

    const isAllInputsFilled = isFormValid.filter((status) => !status).length === 1 && form.querySelector('.email.invalid.filled');

    if (!isAllInputsFilled) {
      form.classList.add('invalid');
    }
  };

  button.onclick = (e) => {
    e.preventDefault();

    const formData = [...(new FormData(form).entries())];
    const url = new URL(document.location.href);
    const savedQueryParams = window.localStorage?.getItem('savedQueryParams')
    let ycid = null;

    if (url.search.length === 0 && savedQueryParams) {
      const searchParams = new URLSearchParams(savedQueryParams);
      searchParams.entries().forEach(([key, value]) => {
        url.searchParams.append(key, value)
      })
    }

    ym(49633516, 'getClientID', clientID => {ycid = clientID});

    const dataObject = formData.reduce(
      (acc, [key, val]) => ({ ...acc, [key]: val }), 
      { 
        urlUtm: url.href.replace('#form-demo', ''),
        ycid,
      }
    );

    if (validateForm().includes(false)) {
      updateInputsClasses();

      return;
    }

    inputs.forEach((input) => {
      if (input) {
        input.value = '';
      }
    });

    content.classList.add('success');
    window?.dataLayer?.push ({ event:'request_demo_form_send' });

    Object.keys(dataObject).forEach((key) => {
      dataObject[key] = dataObject[key]?.toLowerCase()?.replaceAll('>', '&#62;')
      .replaceAll('<', '&#60;')
      .replaceAll('&', '&#38;')
      .replaceAll('eval', '&#101;&#118;&#97;&#108;')
    });

    fetch(api, {
      method: 'post',
      body: JSON.stringify(dataObject),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
      setTimeout(() => {
        content.classList.remove('success');
        demoModal.style.display = 'none';
      }, 5000);
    });

  };

  inputs.forEach((input) => {
    const inputWrapper = input.parentElement.parentElement;

    input.onkeyup = () => {
      if (!button.classList.contains('invalid')) {
        return;
      }

      const inputsValidationStatus = validateForm();
      const isAllInputsValid = !inputsValidationStatus.includes(false);
      const isAllInputsFilled = isAllInputsValid || 
        (inputsValidationStatus.filter((status) => !status).length === 1 && form.querySelector('.email.invalid.filled'));

      inputWrapper.classList.remove('invalid', 'filled');
      form.classList.remove('invalid');
      form.classList.add('half');

      if (isAllInputsFilled) {
        form.classList.remove('half');
      }

      if (isAllInputsValid) {
        button.classList.remove('invalid');
      } else {
        updateInputsClasses();
      }
    }
  });

  if(window.location.hash) {
    const hash = window.location.hash.substring(1);
    if(hash === 'form-demo'){
      demoModal.style.display = 'flex';
      window?.dataLayer?.push ({ event: 'request_demo_form_view' });
    }
  }
})(window);